<script setup>
import {ref, watch} from 'vue'

const props = defineProps({
  usage: Number,
  workingPrice: Number,
  basePrice: Number,
})

const usage = ref(props.usage)

const workingPrice = ref(props.workingPrice)
const basPrice = ref(props.basePrice)
const costs = ((usage.value * workingPrice.value) / 100) + (12 * basPrice.value);

const customerWorkingPrice = ref()
const customerBasePrice = ref()
let customerCosts = ref(0);

watch(customerWorkingPrice, () => {
  if (missingParameter()) {
    customerCosts = 0;
    return;
  }
  calculate();
})

watch(customerBasePrice, () => {
  if (missingParameter()) {
    customerCosts = 0;
    return;
  }
  calculate();
})

function missingParameter() {
  if (customerBasePrice.value === undefined || customerWorkingPrice.value === undefined) {
    return true;
  }

  return isNaN(Number(customerWorkingPrice.value.replace(",", ".")))
      || isNaN(Number(customerBasePrice.value.replace(",", ".")));
}

function calculate() {
  let bus = Number(customerWorkingPrice.value.replace(",", "."));
  let bahn = Number(customerBasePrice.value.replace(",", "."));

  customerCosts = ((bus * usage.value) / 100) + bahn;
}

function floatAsString(toString, attach = '') {
  if (toString) {
    if (isFloat(toString)) {
      if (typeof toString !== "number") {
        toString = Number(toString)
      }
      toString = toString.toFixed(2);
    }
    return toString.toString().replace('.', ',') + ' ' + attach;
  }
  return '';
}

function isFloat(x) {
  return !!(x % 1);
}
</script>

<template>
  <p>
    Wir legen großen Wert auf Transparenz und sind von der Qualität unseres Angebots überzeugt. Daher möchten wir dir
    die Gelegenheit bieten, deinen aktuellen Tarif mit unserem Angebot zu vergleichen.
  </p>
  <p>
    Alles, was du dafür benötigst,
    sind der Arbeitspreis in Cent pro Kilowattstunde sowie der jährliche Grundpreis in Euro deines derzeitigen
    Stromanbieters.
  </p>
  <p>
    Dein Jahresverbrauch: {{ usage }} kWh
  </p>
  <div class="table-responsive">
    <table class="table">
      <thead>
      <tr>
        <th>
          #
        </th>
        <th>
          Unser Angebot
        </th>
        <th>
          Dein aktueller Tarif
        </th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>
          Arbeitspreis:
        </td>
        <td>
          {{ floatAsString(workingPrice, 'ct/kWh') }}
        </td>
        <td>
          {{ floatAsString(customerWorkingPrice, 'ct/kWh') }}
        </td>
      </tr>
      <tr>
        <td>
          Grundpreis
        </td>
        <td>
          {{ floatAsString(basePrice * 12, '€/Jahr') }}
        </td>
        <td>
          {{ floatAsString(customerBasePrice, '€/Jahr') }}
        </td>
      </tr>
      <tr>
        <td>
          Gesamtkosten
        </td>
        <td>
          {{ floatAsString(costs, '€/Jahr') }}
        </td>
        <td>
          {{ floatAsString(customerCosts, '€/Jahr') }}
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <p>
    Bitte trage in die beiden Felder den Arbeitspreis sowie den jährlichen Grundpreis deines aktuellen Stromanbieters
    ein.
  </p>
  <form class="row">
    <div class="col-md-6">
      <label
          for="customer-working-price"
          class="form-label"
      >
        Dein aktueller Arbeitspreis in Cent:
      </label>
      <input
          id="customer-working-price"
          type="text"
          class="form-control"
          v-model="customerWorkingPrice"
          placeholder="Dein Arbeitspreis in Cent"
      />
    </div>
    <div class="col-md-6 pt-3 pt-md-0">
      <label
          for="customer-base-price"
          class="form-label"
      >
        Dein aktueller Grundpreis in Euro:
      </label>
      <input
          id="customer-base-price"
          type="text"
          class="form-control col-md-6"
          v-model="customerBasePrice"
          placeholder="Dein Grundpreis in Euro"
      />
    </div>
  </form>

</template>

<style scoped>

</style>